<template>
  <div class="settings pt-2 pb-5">
    <h3 class="title mt-3">Last ned</h3>
    <div class="pt-1 pb-3">
      <p>
        Appen bruker skjema, tabeller og annen informasjon.<br>
        Disse kan du laste ned ved behov.<br>
        Sist lastet ned: {{this.app.storage.get('sync.downloaded') || '-'}}.
      </p>
      <button @click="doDownload()" type="button" class="btn btn-primary px-3"><i class="fas fa-cloud-download-alt"></i> Last ned</button>
    </div>

    <h3 class="title mt-3">Last opp</h3>
    <div class="pt-1 pb-3">
      <div>
        Appen laster opp informasjon og bilder automatisk. I spesielle tilfeller kan du slå av dette.
        <div class="form-check">
          <input v-model="uploadAutomatic" class="form-check-input" type="checkbox" value="on" id="chkAutoUpload" @click="toggleAutoUpload()">
          <label class="form-check-label" for="chkAutoUpload">
            Last opp automatisk
          </label>
        </div>
      </div>

      <p class="mt-3">
        <span v-if="app.countElementsToUpload() > 0">Det er informasjon som ikke er lastet opp enda; klikk på knappen for å laste opp nå.</span>
        <span v-else>All informasjon er lastet opp.</span><br>
        Sist lastet opp: {{this.app.storage.get('sync.uploaded') || '-'}}.
      </p>
      <button @click="showUpload()" type="button" class="btn btn-primary px-3"><i class="fas fa-cloud-upload-alt"></i>Last opp</button>
    </div>

    <h3 class="title mt-3">Rydding</h3>
    <div class="pt-1 pb-3">
      <p>
        Du kan her rydde opp i informasjon og bilder som allerede er lastet opp.
        Fjern opplastet informasjon eldre enn:<br>
        <select v-model="cleanupAge" class="form-select mt-1" id="cleanupAge" @change="setCleanupAge">
          <option value="-1">Alt som er lastet opp</option>
          <option value="7">1 uke</option>
          <option value="14">2 uker</option>
          <option value="21">3 uker</option>
          <option value="30">1 måned</option>
          <option value="60">2 måneder</option>
          <option value="90">3 måneder</option>
        </select>
      </p>
      <button @click="showCleanupImages()" type="button" class="btn btn-primary px-3 my-1"><i class="fas fa-trash-alt"></i>Rydd opp bilder</button>
      <button @click="showCleanupInformation()" type="button" class="btn btn-primary px-3 my-1"><i class="fas fa-trash-alt"></i>Rydd opp informasjon</button>

      <!-- <div class="mt-3">
        Du kan også la appen rydde opp automatisk.
        <div class="form-check">
          <input v-model="cleanupAutomatic" class="form-check-input" type="checkbox" value="on" id="chkAutoCleanup" @click="toggleAutoCleanup()">
          <label class="form-check-label" for="chkAutoCleanup">
            Rydd opp automatisk
          </label>
        </div>
      </div> -->
    </div>
  </div>

  <div class="modal fade" id="downloadModal" tabindex="-1" aria-labelledby="downloadModalLabel" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="downloadModalLabel">Last ned</h5>
        </div>
        <div class="modal-body overflow-auto" style="max-height:350px">

          <p id="download_inform"></p>

          <div class="progress">
            <div id="download_progress" class="progress-bar progress-bar-striped bg-success" role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
          </div>

        </div>
        <div class="modal-footer">
          <button v-if="download_progress==1" type="button" class="btn btn-sm" disabled>Et øyeblikk...</button>
          <button v-if="download_progress==2" type="button" class="btn btn-sm btn-primary px-2" data-bs-dismiss="modal">OK</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="uploadModal" tabindex="-1" aria-labelledby="uploadModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="uploadModalLabel">Last opp</h5>
        </div>
        <div class="modal-body overflow-auto" style="max-height:350px">

          <p id="upload_inform">
            Et øyeblikk mens informasjonen lastes opp...
          </p>

          <div class="progress">
            <div id="upload_progress" class="progress-bar progress-bar-striped bg-success" role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
          </div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-sm btn-primary px-2" data-bs-dismiss="modal" @click="hideUpload()">OK</button>
        </div>
      </div>
    </div>
  </div>
  
  <div class="modal fade" id="cleanupModal" tabindex="-1" aria-labelledby="cleanupModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="cleanupModalLabel">Rydd opp</h5>
        </div>
        <div class="modal-body overflow-auto" style="max-height:350px">

          <p id="cleanup_inform">
            Et øyeblikk mens applikasjonen rydder opp...
          </p>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-sm btn-primary px-2" data-bs-dismiss="modal" @click="hideCleanup()">OK</button>
        </div>
      </div>
    </div>
  </div>
  
</template>

<script>
import { Modal } from 'bootstrap';

export default {
  data() {
    return {
      download_progress: 0,
      uploadAutomatic: this.app.storage.has('app.uploadAutomatic') ? this.app.storage.get('app.uploadAutomatic') : true,
      cleanupAutomatic: this.app.storage.has('app.cleanupAutomatic') ? this.app.storage.get('app.cleanupAutomatic') : false,
      cleanupAge: this.app.storage.has('app.cleanupAge') ? this.app.storage.get('app.cleanupAge') : '14',
    }
  },

  methods: {
    doDownload() {
      console.log('doDownload');

      new Modal(document.getElementById( 'downloadModal' ), {}).show();
      this.download_progress = 1;
      this.app
        .download('download_inform','download_progress')
        .finally(() => {
          this.download_progress = 2;
        });
    },

    showUpload() {
      // The upload routine is running continuously, and will automatically update the modal if it's available
      // But it needs to be told to show the uploading status
      console.log('showUpload');

      this.app.api.checkToken().then(() => {
        this.app.storage.set( 'app.uploadManual', true );
        this.app.api.upload_total = this.app.countElementsToUpload();
        this.app.api.upload_done = 0;

        // Initial display
        document.getElementById( 'upload_inform' ).innerText = 'Et øyeblikk mens informasjonen lastes opp...';
        document.getElementById( 'upload_progress' ).style.width = 0;

        // Open the modal
        new Modal(document.getElementById( 'uploadModal' ), {
          backdrop: 'static',
          keyboard: false,
          focus: false
        }).show();
      }).catch((error) => {
        console.error(error);

        // Initial display
        document.getElementById( 'upload_inform' ).innerText = 'Vennligst logg av og på, og prøv igjen...';
        document.getElementById( 'upload_progress' ).style.width = 0;

        // Open the modal
        new Modal(document.getElementById( 'uploadModal' ), {
          backdrop: 'static',
          keyboard: false,
          focus: false
        }).show();
      });
    },

    hideUpload() {
      // The upload routine will keep on running continuously, but it needs to be told to stop showing the status
      console.log('hideUpload');
      this.app.storage.set( 'app.uploadManual', false );

      // Close the modal
      new Modal(document.getElementById( 'uploadModal' ), {}).hide();
    },

    toggleAutoUpload() {
      // For some counter intuitive reason, we need to negate this.uploadAutomatic (checkbox clicked = false, unclicked = true)
      console.log('toggleAutoUpload');
      this.app.storage.set( 'app.uploadAutomatic', !this.uploadAutomatic );

      // See if last_sync, which is set by upload(), is longer than this.app.uploadTimerSlow ago
      let lastRun = new Date( this.app.uploadLastRun );
      let now = new Date();
      console.log( now.getTime() -lastRun.getTime() );
      if( now.getTime() -lastRun.getTime() > this.app.uploadTimerSlow ) {
        // It has been a while since upload() ran - so it's most likely not running anymore - so start it
        console.log( 'application.upload() has not run for a while; attempting to restart it.' );
        this.app.upload( true );
      }
    },

    showCleanupImages() {
      document.getElementById( 'cleanup_inform' ).innerText = 'Et øyeblikk. Appen rydder opp i bilder...';

      // Open the modal
      new Modal(document.getElementById( 'cleanupModal' ), {
        backdrop: 'static',
        keyboard: false,
        focus: false
      }).show();

      this.app.cleanup(this.cleanupAge,false,true);
    },

    showCleanupInformation() {
      document.getElementById( 'cleanup_inform' ).innerText = 'Et øyeblikk. Appen rydder opp i informasjon...';

      // Open the modal
      new Modal(document.getElementById( 'cleanupModal' ), {
        backdrop: 'static',
        keyboard: false,
        focus: false
      }).show();

      this.app.cleanup(this.cleanupAge,true,false);
    },

    hideCleanup() {
      // Close the modal
      new Modal(document.getElementById( 'cleanupModal' ), {}).hide();
    },

    setCleanupAge() {
      console.log('setCleanupAge');
      this.app.storage.set( 'app.cleanupAge', this.cleanupAge );
    },

    toggleAutoCleanup() {
      // For some counter intuitive reason, we need to negate this.uploadAutomatic (checkbox clicked = false, unclicked = true)
      console.log('toggleAutoCleanup');
      this.app.storage.set( 'app.cleanupAutomatic', !this.cleanupAutomatic );
    }
  }
}
</script>