<template>
  <div class="home mt-2 mb-5">
     <div class="container">
      <div class="py-2 text-center">
        Hei {{app.user.username}},<br>
        <span class="tiny">Du er koblet til {{app.api.server}}.</span>
      </div>
    </div>

    <div class="container">
      <div v-if="info" class="py-2">
        <nav>
          <div class="nav nav-tabs" id="nav-tab" role="tablist">
            <button class="nav-link px-2 active" id="nav-information-tab" data-bs-toggle="tab" data-bs-target="#nav-information" type="button" role="tab" aria-controls="nav-information" aria-selected="true">Info</button>
            <button class="nav-link px-2" id="nav-projects-tab" data-bs-toggle="tab" data-bs-target="#nav-projects" type="button" role="tab" aria-controls="nav-projects" aria-selected="false">Prosjekt</button>
            <button class="nav-link px-2" id="nav-drawings-tab" data-bs-toggle="tab" data-bs-target="#nav-drawings" type="button" role="tab" aria-controls="nav-drawings" aria-selected="false">Tegning</button>
          </div>
        </nav>
        <div class="tab-content" id="nav-tabContent">

          <div class="tab-pane fade py-4 show active" id="nav-information" role="tabpanel" aria-labelledby="nav-information-tab">
            <div v-if="info" class="accordion accordion-flush border" id="accordionInfo">
              <div v-for="i in info" :key="i" class="accordion-item">
                <h2 class="accordion-header" :id="'headingInfo_' +i.key">
                  <button class="accordion-button px-2 py-3 collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapseInfo_' +i.key" aria-expanded="false" :aria-controls="'collapseInfo_'+i.key">
                    {{i.title}}
                  </button>
                </h2>
                <div :id="'collapseInfo_'+i.key" class="accordion-collapse collapse" :aria-labelledby="'headingInfo_'+i.key" data-bs-parent="#accordionInfo">
                  <div class="accordion-body px-2 py-3">
                    <button @click="doLoadForm(i.key)" class="btn btn-primary btn-sm mb-3">Lag ny post</button>
                    <table class="table tiny table-borderless" :key="forceRedraw">
                      <tbody>
                        <tr v-for="d in data(i.key)" :key="d" @click="doShowDetails(d)">
                          <td v-for="column in i.columns" :key="column" class="border-top">{{d.data[column] || '-'}}</td>
                          <td class="text-end text-black-25 border-top"><span :hidden="(d.status == 'local')"><i class="fas fa-cloud"></i></span></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="tab-pane fade py-3" id="nav-projects" role="tabpanel" aria-labelledby="nav-projects-tab">
            Her vises aktuelle prosjekt.
            Mangler du tilgang til et prosjekt kan du alltid velge den fra listen.
            <ul class="mt-3">
              <li v-for="p in projects" :key="p">
                {{p.number}}: {{p.description}}
              </li>
            </ul>
          </div>

          <div class="tab-pane fade py-3" id="nav-drawings" role="tabpanel" aria-labelledby="nav-drawings-tab">
            Her vises tegningene du har tilgang til.
            Mangler du en tegning, ta kontakt med kontoret.

            <div class="accordion py-3" id="drawings">
              <div v-for="d in drawings" :key="d" class="accordion-item">
                <h2 class="accordion-header">
                  <button class="accordion-button p-2 py-3 collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#'+d.app_id" aria-expanded="false" :aria-controls="d.app_id">
                    {{d.number}}: {{d.description}}
                  </button>
                </h2>
                <div :id="d.app_id" class="accordion-collapse collapse" data-bs-parent="#drawings">
                  <div class="accordion-body pe-0">

                    <div class="accordion" :id="'drawings_' +d.app_id">
                      <div v-for="b in d.building" :key="b" class="accordion-item">
                        <h2 class="accordion-header">
                          <button class="accordion-button p-2 py-3 collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#'+b.app_id" aria-expanded="false" :aria-controls="b.app_id">
                            {{b.building}}
                          </button>
                        </h2>
                        <div :id="b.app_id" class="accordion-collapse collapse" :data-bs-parent="'#drawings_' +d.app_id">
                          <div class="accordion-body pe-0">

                            <div class="accordion" :id="'drawings_' +b.app_id">
                              <div v-for="key_floor in Object.keys(b.floor).sort()" :key="b.floor[key_floor]" class="accordion-item">
                                <h2 class="accordion-header">
                                  <button class="accordion-button p-2 py-3 collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#'+b.floor[key_floor].app_id" aria-expanded="false" :aria-controls="b.floor[key_floor].app_id">
                                    {{b.floor[key_floor].floor}}
                                  </button>
                                </h2>
                                <div :id="b.floor[key_floor].app_id" class="accordion-collapse collapse" :data-bs-parent="'#drawings_' +b.app_id">
                                  <div class="accordion-body row">

                                    <div v-for="r in b.floor[key_floor].revision" :key="r" class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                      <div class="text-center">
                                        <img @click="doLoadDrawing(r.fk_project +'/' +r.filename, r)" :id="r.app_id" :alt="r.revision" :data-src="r.fk_project +'/' +r.filename" class="img-fluid" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8z8BQDwAEhQGAhKmMIQAAAABJRU5ErkJggg==" /><br>
                                        <small>rev. {{r.revision}}</small>
                                      </div>
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-else class="mt-3 text-center">
        Appen trenger å laste ned oppsettet først.
        <br>
        <router-link class="btn btn-primary px-3 mt-4" to="/sync" replace><i class="fas fa-cloud-download-alt"></i>Last ned</router-link>
      </div>

    </div>

  </div>
</template>

<style scoped>
td {
  padding-left: 0;
}
/* Hide the button when a accordion item is expanded */
#accordionInfo .accordion-button:not(.collapsed)::after {
  display: none;
}
#drawings img {
  border: 1px solid gray;
}
</style>

<script>
export default {
  name: 'Home',

  data() {
    return {
      // When the database updates, it will transmit an event that gets picked up; it will use this forceRedraw to force a -wait for it- redraw
      forceRedraw: 0,
    }
  },

  computed: {
    info() {
      let forms_info = this.app.storage.get('info.forms_info');
      return forms_info;
    }
  },

  methods: {
    doLoadForm(key) {
      console.log('doLoadForm(' +key +')');
      this.app.loadForm(key);
    },

    doLoadDrawing(drawing, drawingInfo) {
      console.log('doLoadDrawing(' +drawing +',<drawingInfo>)');
      let info = {
        fk_project: drawingInfo.fk_project,
        building: drawingInfo.building,
        floor: drawingInfo.floor,
        axis: JSON.parse(drawingInfo.axis) || {}
      };
      let show = {
          Prosjekt: drawingInfo.project,
          Bygg: drawingInfo.building || '-',
          Etasje: drawingInfo.floor || '-',
          Revisjon: drawingInfo.revision || '-',
          Referanse: drawingInfo.reference || '-'
      };
      this.app.drawing.loadDrawing(drawing, info, show);
    },

    doShowDetails(row) {
      console.log('doShowDetails');

      // Get the form info for this record so we know what to show and where to go
      let forms = this.app.storage.get('info.forms_info');
      let form = null;
      for (let cnt in forms) {
        if (forms[cnt].key == row.type) {
          form = forms[cnt];
        }
      }

      // Calculate an overview over the values
      let innerHTML = '';
      for (let column in form.columns) {
        if( row.data[form.columns[column]] ) {
          innerHTML += this.app.escapeHTML(column) + ': <span class="' + form.columns[column] + '">' + this.app.escapeHTML(row.data[form.columns[column]]) + '</span><br>';
        }
      }

      // Give the ok button something to do
      document.getElementById('infoModalOK').onclick = () => {
          this.app.loadForm(row.type, row);
      };

      // Show the modal with the buttons, with a very light backdrop (so the user sees the marker), the modal towards the bottom of the screen and only one cancel button
      document.getElementById('infoModalLabel').innerText = form.title;
      document.getElementById('infoModalDescription').innerHTML = innerHTML;
      document.getElementById('infoModal')._modal.show();
    },

    data(key) {
      let result = this.app.storage.getAll('created.' +key +'.');
      result.sort( (a,b) => {
        return (a.createdAt > b.createdAt ? -1 : 1)
      });
      return result;
    }
  },

  beforeMount() {
    this.projects = this.app.storage.get('info.projects_assigned');
    this.drawings = this.app.storage.get('info.projects_drawings');

    let app = this.app.storage.get('info.app');
    if( app && app.css ) {
      let element = document.getElementById('app_css');
      if( !element ) {
        element = document.createElement('style');
        element.id = 'app_css';
      }
      element.setAttribute('type', 'text/css');
      if ('textContent' in element) {
        element.textContent = app.css;
      } else {
        element.styleSheet.cssText = app.css;
      }
      document.getElementsByTagName('head')[0].appendChild(element);
    }
  },

  mounted() {
    try {
      let els = document.querySelectorAll( 'img[data-src]' );
      els.forEach( (el) => {
        this.app.storage.loadBlob('drawings.' +el.dataset.src, el.id);
      });

      // When the database is changed, it emits an event; react to it to redraw the information
      window.addEventListener('storage-changed', (data) => {
        console.log( 'storage-changed(' +data.detail.action +')' );
        this.forceRedraw++;

        // See what caused the change-event
        if( data.detail.action == 'upload' ) {
          // If the change was because of an upload, we don't have to reload the image markers
        } else {
          // A post was created, changed or removed, so possibly the marker has too, so reload the markers
          // Using even 2 requestAnimationFrame did not work, so we'll give it 1/10th of a second to paint, followed by one requestAnimationFrame :-(
          window.setTimeout(() => {
              requestAnimationFrame(() => {
                  this.app.drawing.markersDisplay();
              });
          }, 100);
        }
      });

      // Show the menu
      document.getElementById('menu').style.display = 'block';

      // Make sure the first tab is visible (sometimes that gets wrong)
      let infoTab = document.getElementById('nav-information-tab');
      if( infoTab ) {
        infoTab.click();
      }
    } catch(e) {
      console.error(e);
    }
  }
}
</script>